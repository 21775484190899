// Detect IE

window.device = {
	browser: '',
	version: false,
	classList: ''
};

( function( window, document, undefined ) {

	// IE Detection
	/**
	 * Get the version of Internet Explorer (IE) running or false if not IE.
	 * @return {Number|false}
	 */
	function getIeVersion() {
	  var ua = window.navigator.userAgent;

	  var msie = ua.indexOf('MSIE ');
	  if (msie > 0) {
	    // IE 10 or older => return version number
	    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	  }

	  var trident = ua.indexOf('Trident/');
	  if (trident > 0) {
	    // IE 11 => return version number
	    var rv = ua.indexOf('rv:');
	    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	  }

	  // other browser
	  return false;
	}

	let ieVersion = getIeVersion();
	if ( ieVersion ) {
		device.browser = 'IE';
		device.version = ieVersion;
		device.classList += ' is-ie is-ie-' + ieVersion;
	}


	// Any other tests needed here


	device.classList = device.classList.trim();

	if ( device.classList ) {
		// Required to split strings and add them separately to avoid IE 11s InvalidCharacterError
		device.classList.split(' ').forEach( function(str) {
			document.documentElement.classList.add(str);
		} );
	}


	function load_style( src ) {
		var head = document.getElementsByTagName('HEAD')[0];  
	    var link = document.createElement('link'); 
	    
	    if ( wp_head.manifest ) {
	        src = wp_head.manifest[src];
	    }

		src = wp_head.stylesheet_directory_uri + 'dist/' + src;

	    link.rel = 'stylesheet';  
	    link.type = 'text/css'; 
	    link.href = src;
	    head.appendChild(link); 
	}

	if ( 'IE' === device.browser ) {
		load_style('css/ie.min.css');
	}


} )(  window, document );
